import styled from 'styled-components';

export const Template = styled.div`
	.text {
		&-align {
			&--left {
				text-align: left;
			}
			&--right {
				text-align: right;
			}
			&--justify {
				text-align: justify;
			}
			&--center {
				text-align: center;
			}
		}
	}

	.mt {
		&--10 {
			margin-top: 10px;
		}
		&--20 {
			margin-top: 20px;
		}
		&--30 {
			margin-top: 30px;
		}
	}

	.mb {
		&--10 {
			margin-bottom: 10px;
		}
		&--20 {
			margin-bottom: 20px;
		}
		&--30 {
			margin-bottom: 30px;
		}
	}

	.w {
		&-100 {
			width: 100%;
		}
		&-90 {
			width: 90%;
		}
		&-80 {
			width: 80%;
		}
		&-70 {
			width: 70%;
		}
		&-60 {
			width: 60%;
		}
		&-50 {
			width: 50%;
		}
	}

	.btn {
		margin: 0;
		padding: 0.25em 1em;
		border-radius: 0.5rem;
		cursor: pointer;
		font-size: 16px;
		color: #fff;

		&.btn--block {
			width: 100%;
			display: block;
		}

		&.disabled {
			opacity: 0.5;
			pointer-events: none;
			cursor: no-drop;
		}

		&.btn--red {
			padding: 10px 20px;
			background-color: #ec1b53;
			border: 1px solid #ec1b53;

			&:hover,
			&:focus {
				background-color: #d5083f;
				border-color: #d5083f;
			}
		}
	}

	.form {
		&-control {
			margin: 0;
			padding: 6px 12px;
			height: 42px;
			background: #fff;
			border: 1px solid #e5e5e5;
			border-radius: 0.2rem;
			font-size: 16px;
			color: #6b6b6b;

			&.error {
				border-color: red;

				&:focus,
				&:hover {
					border-color: red;
				}
			}

			&--textarea {
				padding-top: 10px;
				padding-bottom: 10px;
			}

			&--arrow {
				padding-right: 55px;
				position: relative;
				appearance: none;
				background-image: linear-gradient(45deg, transparent 50%, gray 50%),
					linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
				background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
					calc(100% - 2.5em) 0.5em;
				background-size: 5px 5px, 5px 5px, 1px 1.5em;
				background-repeat: no-repeat;
				cursor: pointer;

				&:hover {
					border-color: #ccc;
				}
			}

			&:focus {
				border-color: #ccc;
			}

			&::placeholder {
				color: #cacaca;
				opacity: 1;
			}
		}

		&-group {
			& + .form-group {
				margin-top: 10px;
			}

			label {
				display: block;
				margin-bottom: 5px;
			}

			.form-control {
				width: 100%;
			}
		}
	}

	select,
	input,
	button {
		&:disabled {
			background-color: #f2f2f2;
		}
	}

	.d-none {
		display: none !important;
	}
	.d-block {
		display: block;
	}
`;
