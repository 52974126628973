import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player/youtube';

const Wrapper = styled.div`
	z-index: 99;
	position: absolute;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.5);

	.modal {
		&--box {
			position: relative;
			width: 100vw;
			height: 100vh;
		}

		&--close {
			position: absolute;
			z-index: 99;
			left: 10px;
			right: 10px;
			bottom: 12vh;
			padding: 10px 10px;
			max-width: 320px;
			margin: 0 auto;
			border-radius: 100px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #34af23;
			box-shadow: 0 0 10px rgba(000, 000, 000, 1);
			cursor: pointer;
			text-transform: uppercase;
			font-size: 22px;
			font-weight: 500;
			color: #fff;
		}

		&--video {
			position: absolute;
			inset: 0;
			z-index: 99;
			width: 100%;
			height: 100%;
		}
	}
`;

const ModalVideo = ({ closeAction, youtube }) => {
	const videoURL = `https://www.youtube.com/watch?v=${youtube}`;

	const [playing, setPlaying] = React.useState(false);
	const [controls] = React.useState(false);
	const [loop] = React.useState(true);

	const handlePlay = () => setPlaying(true);
	const handlePause = () => setPlaying(false);

	React.useLayoutEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<Wrapper>
			<div className="modal--box">
				<div className="modal--video">
					<ReactPlayer
						url={videoURL}
						playing={playing}
						width={'100vw'}
						height={'100vh'}
						loop={loop}
						controls={controls}
						onPlay={handlePlay}
						onPause={handlePause}
					/>
				</div>

				<div className="modal--close" onClick={closeAction}>
					Ver ofertas
				</div>
			</div>
		</Wrapper>
	);
};

export default ModalVideo;
