import React from 'react';
import styled from 'styled-components';
import useFetch from '../hooks/useFetch';
import useQuery from '../hooks/useQuery';
import Loading from '../components/Loading';
import SelectShops from '../components/SelectShops';
import Foobar from '../components/Foobar';
import ModalVideo from '../components/ModalVideo';

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';

const Wrapper = styled.div`
	margin-bottom: 60px;

	.title {
		margin: 0;
		font-size: 18px;
	}

	.hr {
		border-color: #eee;
	}

	.shop {
		&__info {
		}

		&__name {
			margin: 0;
			font-size: 16px;
		}

		&__city {
			margin: 0;
			font-size: 13px;
		}
	}
`;

const HomePage = (props) => {
	const [shop, setShop] = React.useState([]);
	const [shops, setShops] = React.useState([]);
	const [flyers, setFlyers] = React.useState([]);
	const [videos, setVideos] = React.useState([]);
	const [modalVideo, setModalVideo] = React.useState(true);

	const query = useQuery();
	const queryUf = query.get('uf');
	const queryShopId = query.get('id');
	const queryVideo = query.get('video');
	const queryYouTube = query.get('youtube');

	const getShopList = useFetch('/NossasLojas__JSON.php?action=getStores');
	const getItemsShowcase = useFetch(`/sm-rds-ofertas__JSON.php?action=getOffers&store=${queryShopId}`);

	const date = new Date();

	React.useEffect(() => {
		if (getShopList) {
			const arr = [];
			getShopList.forEach((e) => {
				if (e.id === queryShopId) {
					arr.push(e);
				}
			});
			setShop(arr);
			setShops(getShopList);
		}
	}, [getShopList, queryShopId]);

	React.useEffect(() => {
		if (getItemsShowcase) {
			const arr_f = [];
			const arr_v = [];
			getItemsShowcase.offers.forEach((fly, index) => {
				fly.flyer ? arr_f.push(fly.flyer[0].item) : arr_v.push(fly.video[0].item);
			});
			setFlyers(arr_f);
			setVideos(arr_v);
		}
	}, [getItemsShowcase]);

	const handleShopSelected = (e) => {
		props.history.push(`/?uf=${queryUf}&id=${e.target.value}`);
		setFlyers([]);
		setVideos([]);
	};

	return (
		<Wrapper>
			{modalVideo && queryVideo === 'true' && !!queryYouTube && (
				<ModalVideo closeAction={() => setModalVideo(!true)} youtube={queryYouTube} />
			)}

			<div className="container">
				{shops && queryUf && queryShopId && (
					<div className="row d-flex flex-row-reverse align-items-md-center">
						<div className="col-md-6 offset-lg-2 col-lg-4">
							<SelectShops shops={shops} handleShopSelected={handleShopSelected} />
						</div>
						<div className="col-md-6 col-lg-6 mt-4 mt-md-0">
							{shop.length > 0 && (
								<div className="shop__info">
									<h2 className="shop__name">{shop[0].nome}</h2>
									<p className="shop__city">
										{shop[0].cidade} / {shop[0].uf}
									</p>
								</div>
							)}
						</div>
					</div>
				)}

				{shops && queryUf && !queryShopId && (
					<div className="row">
						<div className="offset-sm-3 col-sm-6 text-center">
							<h2>Selecione uma loja</h2>
							<SelectShops shops={shops} handleShopSelected={handleShopSelected} />
						</div>
					</div>
				)}
			</div>

			{queryShopId && queryUf && !flyers.length && !videos.length && <Loading />}

			{flyers.length > 0 && (
				<div className="container mt-4">
					<h2 className="title mb--10">Ofertas do encarte:</h2>
					<div className="row">
						{flyers.map((flyer, flyerIndex) => (
							<div key={flyerIndex} className="col-6 col-sm-6 col-md-3">
								<Gallery>
									{flyer.map((item, itemIndex) => (
										<Item
											key={itemIndex}
											original={item.image}
											thumbnail={item.image}
											width={item.width}
											height={item.height}
										>
											{({ ref, open }) => (
												<img
													ref={ref}
													onClick={open}
													src={item.image}
													className={
														itemIndex === 0 ? 'img-fluid flyer__cover mt-2 mb-2' : 'd-none'
													}
													alt="Imagem da oferta"
												/>
											)}
										</Item>
									))}
								</Gallery>
							</div>
						))}
					</div>
				</div>
			)}

			{videos.length > 0 && (
				<div className="container">
					<hr className="hr mt-5 mb-5" />
					<h2 className="title mb--10">
						Ofertas em vídeo válidas para {date.toISOString().substr(0, 10).split('-').reverse().join('/')}:
					</h2>
					<div className="row">
						{videos.map((video, videoIndex) => (
							<div key={videoIndex} className="col-12 col-sm-6 col-md-6 col-lg-3 mt-2 mb-2">
								<div className="embed-responsive embed-responsive-16by9">
									<iframe
										className="embed-responsive-item"
										src={`https://www.youtube.com/embed/${video[0].youtubeID}?rel=0`}
										allowFullScreen
										title="Vídeo da oferta"
									></iframe>
								</div>
							</div>
						))}
					</div>
				</div>
			)}

			{shop.length > 0 && <Foobar whatsapp={shop[0].whatsapp} />}
		</Wrapper>
	);
};

export default HomePage;
