import { useLayoutEffect, useState } from 'react';

export const useWindowSize = () => {
	const [size, setSize] = useState([0, 0]);

	useLayoutEffect(() => {
		function updateSize() {
			const clientWidth = document.documentElement.clientWidth;
			const clientHeight = document.documentElement.clientHeight;

			//setSize([window.innerWidth, window.innerHeight]);
			setSize([clientWidth, clientHeight]);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
};

export const useWindowSizeWidth = () => {
	const [size, setSize] = useState(0);

	useLayoutEffect(() => {
		function updateSize() {
			const clientWidth = document.documentElement.clientWidth;
			setSize(clientWidth);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
};

export const useWindowSizeHeight = () => {
	const [size, setSize] = useState(0);

	useLayoutEffect(() => {
		function updateSize() {
			const clientWidth = document.documentElement.clientHeight;
			setSize(clientWidth);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
};
