import React from 'react';
import imageLoad from '../images/loading.svg';

const Loading = () => {
	return (
		<div className="conatiner text-center">
			<img src={imageLoad} alt="Carregando" width="100" />
			<h2 style={{ fontSize: '20px' }}>Carregando...</h2>
		</div>
	);
};

export default Loading;
