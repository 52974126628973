const colors = {
	white: '#fff',
	dark: '#0a0a0a',
	gray: '#6d6f78',
	blueDark: '#1c274e',
	blueLight: '#2b3868',
	red: '#cc3000',
};

export default colors;
